exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-about-the-project-tsx": () => import("./../../../src/pages/en/about-the-project.tsx" /* webpackChunkName: "component---src-pages-en-about-the-project-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-projektu-tsx": () => import("./../../../src/pages/o-projektu.tsx" /* webpackChunkName: "component---src-pages-o-projektu-tsx" */),
  "component---src-pages-vysledky-mereni-tsx": () => import("./../../../src/pages/vysledky-mereni.tsx" /* webpackChunkName: "component---src-pages-vysledky-mereni-tsx" */),
  "component---src-posts-jake-jsou-zdroje-znecisteni-ovzdusi-v-ruznych-castech-tisnova-tsx": () => import("./../../../src/posts/jake-jsou-zdroje-znecisteni-ovzdusi-v-ruznych-castech-tisnova.tsx" /* webpackChunkName: "component---src-posts-jake-jsou-zdroje-znecisteni-ovzdusi-v-ruznych-castech-tisnova-tsx" */),
  "component---src-posts-ovlivnuji-kvalitu-ovzdusi-v-tisnove-lokalni-topeniste-tsx": () => import("./../../../src/posts/ovlivnuji-kvalitu-ovzdusi-v-tisnove-lokalni-topeniste.tsx" /* webpackChunkName: "component---src-posts-ovlivnuji-kvalitu-ovzdusi-v-tisnove-lokalni-topeniste-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

